<script>
import { decodeWord, encodeWord } from '@/utils/util.js'
export default {
    name: 'item-component',
    props: {
        index: { // index of current item
            type: Number
        },
        source: { // here is: {uid: 'unique_1', text: 'abc'}
            type: Object,
            default() {
                return {}
            }
        }
    },
    render() {
        const { source: { text: { value, target, values, type , remark }, onClick } } = this
        console.log({ value, target, values, type , remark });
        // return <p class="inspection-result" vOn:click={onClick}>{`${this.index + 1}. ${decodeWord(value)}${target ? ` -> ${target}` : ''}`}</p>
        var targetValue = target
        if (values?.length) {
            targetValue = values?.filter(i => value !== i).join(" -> ")
        }
        return (<p class="inspection-result" vOn:click={onClick}>
            <div class='inspection-result-value'>
                <div>{`${this.index + 1} . ${decodeWord(value)}${targetValue ? " -> " + decodeWord(targetValue) : ''}`}</div>
            </div>
            {typeof remark === 'string' && (<span class='mix-up-remark'>{remark}</span>)}
        </p>)
    },

}
</script>

<style scoped lang="less">
.inspection-result {
    background: white;
    padding: 5px 7px;
    border-bottom: 1px solid #e8e8e8;
    margin: 0;
}
.mix-up-remark {
  white-space: pre-line;
  word-break: break-word;
}
.inspection-result:last-child {
    border-bottom: none;
}
</style>